var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "orderSyncForm",
          attrs: { model: _vm.form, "label-width": "120px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "供应商：", prop: "supplierId" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { change: _vm.changeSupplier },
                  model: {
                    value: _vm.form.supplierId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "supplierId", $$v)
                    },
                    expression: "form.supplierId"
                  }
                },
                _vm._l(_vm.supplierOptions, function(item) {
                  return _c(
                    "el-radio-button",
                    { key: item.ID, attrs: { label: item.ID } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.form.supplierId === 1001
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "订单模式：", prop: "mode" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.form.mode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "mode", $$v)
                          },
                          expression: "form.mode"
                        }
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 1 } }, [
                          _vm._v("PID")
                        ]),
                        _c("el-radio-button", { attrs: { label: 2 } }, [
                          _vm._v("渠道ID")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "时间场景：", prop: "scene" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.form.scene,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "scene", $$v)
                          },
                          expression: "form.scene"
                        }
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 1 } }, [
                          _vm._v("按订单创建时间")
                        ]),
                        _c("el-radio-button", { attrs: { label: 2 } }, [
                          _vm._v("按订单付款时间")
                        ]),
                        _c("el-radio-button", { attrs: { label: 3 } }, [
                          _vm._v("按订单结算时间")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "订单状态：", prop: "status" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.form.status,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status"
                        }
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 0 } }, [
                          _vm._v("全部")
                        ]),
                        _c("el-radio-button", { attrs: { label: 3 } }, [
                          _vm._v("订单结算")
                        ]),
                        _c("el-radio-button", { attrs: { label: 12 } }, [
                          _vm._v("订单付款")
                        ]),
                        _c("el-radio-button", { attrs: { label: 13 } }, [
                          _vm._v("订单失效")
                        ]),
                        _c("el-radio-button", { attrs: { label: 14 } }, [
                          _vm._v("订单成功")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm.form.supplierId === 1003
            ? _c(
                "el-form-item",
                { attrs: { label: "时间场景：", prop: "scene" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.scene,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "scene", $$v)
                        },
                        expression: "form.scene"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("下单时间")
                      ]),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("完成时间")
                      ]),
                      _c("el-radio-button", { attrs: { label: 3 } }, [
                        _vm._v("更新时间")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.cpsSceneShow
            ? _c(
                "el-form-item",
                { attrs: { label: "时间场景：", prop: "scene" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.form.scene,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "scene", $$v)
                        },
                        expression: "form.scene"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("下单时间")
                      ]),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("更新时间")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "时间类型：", prop: "time" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.form.time,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "time", $$v)
                    },
                    expression: "form.time"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("近几小时到现在")
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v("近几天到现在")
                  ]),
                  _c("el-radio-button", { attrs: { label: 3 } }, [
                    _vm._v("指定时间段")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.form.time === 3
            ? _c(
                "el-form-item",
                { attrs: { label: "时间范围：", prop: "time_range" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "default-time": ["00:00:00", "23:59:59"],
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss"
                    },
                    model: {
                      value: _vm.form.timeRange,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "timeRange", $$v)
                      },
                      expression: "form.timeRange"
                    }
                  })
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "近几(小时/天)：", prop: "time_num" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "435px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.timeNum,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "timeNum", _vm._n($$v))
                      },
                      expression: "form.timeNum"
                    }
                  })
                ],
                1
              ),
          _vm.form.supplierId === 1001 || _vm.form.supplierId === 1003
            ? _c(
                "el-form-item",
                { attrs: { label: "指定应用ID：", prop: "app_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "435px" },
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        placeholder: "指定应用(不填则为全部)"
                      },
                      model: {
                        value: _vm.form.appId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "appId", $$v)
                        },
                        expression: "form.appId"
                      }
                    },
                    _vm._l([], function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    "native-type": "button",
                    loading: _vm.submitting
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit("orderSyncForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }