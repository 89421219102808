import service from '@/utils/request'; // @Tags Supplier
// @Summary 创建Supplier
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Supplier true "创建Supplier"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /supplier/createSupplier [post]

export var createSupplier = function createSupplier(data) {
  return service({
    url: "/supplier/createSupplier",
    method: 'post',
    data: data
  });
}; // @Tags Supplier
// @Summary 删除Supplier
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Supplier true "删除Supplier"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /supplier/deleteSupplier [delete]

export var deleteSupplier = function deleteSupplier(data) {
  return service({
    url: "/supplier/deleteSupplier",
    method: 'delete',
    data: data
  });
}; // @Tags Supplier
// @Summary 删除Supplier
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除Supplier"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /supplier/deleteSupplier [delete]

export var deleteSupplierByIds = function deleteSupplierByIds(data) {
  return service({
    url: "/supplier/deleteSupplierByIds",
    method: 'delete',
    data: data
  });
}; // @Tags Supplier
// @Summary 更新Supplier
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Supplier true "更新Supplier"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /supplier/updateSupplier [put]

export var updateSupplier = function updateSupplier(data) {
  return service({
    url: "/supplier/updateSupplier",
    method: 'put',
    data: data
  });
}; // @Tags Supplier
// @Summary 用id查询Supplier
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Supplier true "用id查询Supplier"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /supplier/findSupplier [get]

export var findSupplier = function findSupplier(params) {
  return service({
    url: "/supplier/findSupplier",
    method: 'get',
    params: params
  });
}; // @Tags Supplier
// @Summary 分页获取Supplier列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取Supplier列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /supplier/getSupplierList [get]

export var getSupplierList = function getSupplierList(params) {
  return service({
    url: "/supplier/getSupplierList",
    method: 'get',
    params: params
  });
};