import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderSync } from '@/api/order';
import { getSupplierList } from '@/api/supplier';
export default {
  name: "orderSync",
  data: function data() {
    return {
      supplierOptions: [],
      cpsSceneShow: false,
      submitting: false,
      form: {
        supplierId: 1001,
        mode: 1,
        time: 1,
        timeNum: 1,
        start: "",
        end: "",
        timeRange: [],
        scene: 1,
        status: 0,
        appId: []
      }
    };
  },
  created: function created() {
    var _this = this;

    getSupplierList({
      page: 1,
      pageSize: -1
    }).then(function (res) {
      _this.supplierOptions = res.data.list;
    });
  },
  methods: {
    changeSupplier: function changeSupplier(val) {
      var options = this.supplierOptions;

      for (var i = 0; i < options.length; i++) {
        if (val === options[i].ID) {
          this.cpsSceneShow = options[i].parentId === 5;
          break;
        }
      }
    },
    handleSubmit: function handleSubmit(formName) {
      var _this2 = this;

      if (this.form.time === 3) {
        if (!this.form.timeRange.length) return this.$message.warning('请填写时间范围');
        var now = new Date(new Date().getTime());
        var y = now.getFullYear();
        var m = now.getMonth() + 1;
        var d = now.getDate();
        var x = y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
        if (this.form.timeRange[1] > x) return this.$message.warning('结束时间不能大于当前时间');
        this.form.start = this.form.timeRange[0];
        this.form.end = this.form.timeRange[1];
      } else {
        if (this.form.timeNum < 1) return this.$message.warning('请填写小时/天（大于0）');
      }

      if (this.form.appId.length > 0) {
        var appIds = [];
        this.form.appId.forEach(function (item) {
          appIds.push(parseInt(item));
        });
        this.form.appId = appIds;
      }

      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          orderSync(_objectSpread({}, _this2.form)).then(function (res) {
            _this2.submitting = false;

            _this2.$message.success(res.msg);
          }).catch(function (e) {
            _this2.submitting = false;

            _this2.$message.error(e.message);
          });
        } else {
          _this2.submitting = false;
        }
      });
    }
  }
};